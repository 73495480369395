import { useQuery } from "@tanstack/react-query";
import {
  GetSiteAuditListRequestParameters,
  GetSiteAuditListResponse,
  TruckSystemService,
} from "services/TruckSystemService";

const useAuditList = ({
  siteId,
  ...props
}: GetSiteAuditListRequestParameters) => {
  return useQuery<GetSiteAuditListResponse, Error>({
    queryKey: ["audit-list", { siteId, ...props }],
    queryFn: () =>
      TruckSystemService.getSiteAuditList({
        siteId,
        ...props,
      }),
  });
};

export default useAuditList;
