import React, { useState, useMemo, CSSProperties } from "react";
import {
  CalendarOutlined,
  FilterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table/interface";
import moment from "moment";
import range from "lodash/range";

import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import { useSite } from "contexts/SiteDetailsContext";
import { DateFilterOption } from "types/common/filterOptions";
import SearchFilterInput from "components/common/SearchFilterInput/SearchFilterInput";

const SET_FILTER_COLOR = "#00C9B0";
const UNSET_FILTER_COLOR = "#AAA";

const feedingRoundOptions = range(1, 100).map((num) => ({
  label: num.toString(),
  value: num,
}));

const autoPostOptions = [
  { label: "Auto", value: 1 },
  { label: "Manual", value: 0 },
];

const filterIconStyle = (filter: any): CSSProperties => ({
  color: filter !== null ? SET_FILTER_COLOR : UNSET_FILTER_COLOR,
});

const useAuditRecordListState = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dateSelectorVisible, setDateSelectorVisible] = useState(false);
  const [dateFilter, setDateFilter] = useState<DateFilterOption>(null);
  const [roundFilter, setRoundFilter] = useState(null);
  const [loadNumberFilter, setLoadNumberFilter] = useState(null);
  const [rationNameFilter, setRationNameFilter] = useState(null);
  const [ingredientNameFilter, setIngredientNameFilter] = useState(null);
  const [truckNameFilter, setTruckNameFilter] = useState(null);
  const [driverNameFilter, setDriverNameFilter] = useState(null);
  const [autoPostFilter, setAutoPostFilter] = useState(null);

  const {
    site: { id: siteId },
    siteAdminId,
  } = useSite();

  const showDateModal = (): void => {
    setDateSelectorVisible(true);
  };

  const handleSubmitDates = (
    dateStartModal: moment.Moment,
    dateEndModal: moment.Moment
  ): void => {
    setDateFilter({
      dateStart: dateStartModal,
      dateEnd: dateEndModal,
    });
  };

  const columns: ColumnsType<Audit> = useMemo(
    () => [
      {
        title: (
          <div>
            Date
            <div onClick={showDateModal} className="icon-calendar">
              <CalendarOutlined
                style={{
                  color:
                    dateFilter?.dateStart !== undefined &&
                    dateFilter?.dateEnd !== undefined
                      ? SET_FILTER_COLOR
                      : UNSET_FILTER_COLOR,
                }}
              />
            </div>
          </div>
        ),
        dataIndex: "audit_date",
        key: "audit_date",
        render: (date) =>
          date && moment.parseZone(date).format("M/DD/YY h:mm A"),
        width: 230,
      },
      {
        title: "Round",
        dataIndex: "feeding",
        key: "feeding",
        filterIcon: <FilterOutlined style={filterIconStyle(roundFilter)} />,
        filterDropdown: ({ confirm }) => (
          <FilterDropdown
            onSetSelectedFilter={(_selectedFilter) => {
              setRoundFilter(_selectedFilter ? Number(_selectedFilter) : null);
              confirm();
            }}
            selectedFilter={roundFilter}
            options={feedingRoundOptions}
          />
        ),
      },
      {
        title: "Load ID",
        dataIndex: "load_number",
        key: "load_number",
        filterIcon: () => (
          <SearchOutlined style={filterIconStyle(loadNumberFilter)} />
        ),
        filterDropdown: ({ confirm }) => (
          <SearchFilterInput
            confirm={confirm}
            onClear={() => setLoadNumberFilter(null)}
            onSearch={setLoadNumberFilter}
            value={loadNumberFilter}
            inputType={"number"}
          />
        ),
      },
      {
        title: "Ration Name",
        dataIndex: "ration_name",
        key: "ration_name",
        filterIcon: () => (
          <SearchOutlined style={filterIconStyle(rationNameFilter)} />
        ),
        filterDropdown: ({ confirm }) => (
          <SearchFilterInput
            confirm={confirm}
            onClear={() => setRationNameFilter(null)}
            onSearch={setRationNameFilter}
            value={rationNameFilter}
          />
        ),
      },
      {
        title: "Ingredient",
        dataIndex: "target",
        key: "target",
        filterIcon: () => (
          <SearchOutlined style={filterIconStyle(ingredientNameFilter)} />
        ),
        filterDropdown: ({ confirm }) => (
          <SearchFilterInput
            confirm={confirm}
            onClear={() => setIngredientNameFilter(null)}
            onSearch={setIngredientNameFilter}
            value={ingredientNameFilter}
          />
        ),
      },
      {
        title: "Truck ID",
        dataIndex: "truck_name",
        key: "truck_name",
        filterIcon: () => (
          <SearchOutlined style={filterIconStyle(truckNameFilter)} />
        ),
        filterDropdown: ({ confirm }) => (
          <SearchFilterInput
            confirm={confirm}
            onClear={() => setTruckNameFilter(null)}
            onSearch={setTruckNameFilter}
            value={truckNameFilter}
          />
        ),
      },
      {
        title: "Driver Name",
        dataIndex: "driver_name",
        key: "driver_name",
        filterIcon: () => (
          <SearchOutlined style={filterIconStyle(driverNameFilter)} />
        ),
        filterDropdown: ({ confirm }) => (
          <SearchFilterInput
            confirm={confirm}
            onClear={() => setDriverNameFilter(null)}
            onSearch={setDriverNameFilter}
            value={driverNameFilter}
          />
        ),
      },
      {
        title: "Post Type",
        dataIndex: "auto_post",
        key: "auto_post",
        filterIcon: <FilterOutlined style={filterIconStyle(autoPostFilter)} />,
        filterDropdown: ({ confirm }) => (
          <FilterDropdown
            onSetSelectedFilter={(_selectedFilter) => {
              setAutoPostFilter(
                _selectedFilter !== null ? Number(_selectedFilter) : null
              );
              confirm();
            }}
            selectedFilter={autoPostFilter}
            options={autoPostOptions}
          />
        ),
        render: (autoPost) => <span>{autoPost ? "Auto" : "Manual"}</span>,
      },
    ],
    [
      autoPostFilter,
      dateFilter?.dateEnd,
      dateFilter?.dateStart,
      driverNameFilter,
      ingredientNameFilter,
      loadNumberFilter,
      rationNameFilter,
      roundFilter,
      truckNameFilter,
    ]
  );

  return {
    columns,
    currentPage,
    setCurrentPage,
    dateSelectorVisible,
    setDateSelectorVisible,
    dateFilter,
    handleSubmitDates,
    roundFilter,
    loadNumberFilter,
    rationNameFilter,
    ingredientNameFilter,
    truckNameFilter,
    driverNameFilter,
    autoPostFilter,
    siteId,
    siteAdminId,
  };
};

export default useAuditRecordListState;
