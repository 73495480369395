import axios from "axios";

import { BaseService } from "services/BaseService";
import { auditRecordsPath } from "./constants";

export type GetSiteAuditListResponse = {
  data: Array<Audit>;
  meta: PaginationMetadata;
};

export type GetSiteAuditListRequestParameters = {
  siteId: number;
  siteAdminId?: number;
  page?: number;
  pageSize?: number;
  dateStart?: string;
  dateEnd?: string;
  round?: number;
  loadNumber?: number;
  rationName?: string;
  ingredientName?: string;
  truckName?: string;
  driverName?: string;
  autoPost?: number;
};

export class TruckSystemService extends BaseService {
  public static getSiteAuditList({
    siteId,
    siteAdminId,
    page = 1,
    pageSize = 10,
    dateStart,
    dateEnd,
    round,
    loadNumber,
    rationName,
    ingredientName,
    truckName,
    driverName,
    autoPost,
  }: GetSiteAuditListRequestParameters): Promise<GetSiteAuditListResponse> {
    return axios
      .get(
        BaseService.buildRequestURL(
          {
            site_admin_id: siteAdminId,
            page: page,
            page_size: pageSize,
            from_audit_date: dateStart,
            to_audit_date: dateEnd,
            feeding: round,
            load_number: loadNumber,
            ration_name: rationName,
            ingredient: ingredientName,
            vehicle_id: truckName,
            driver_name: driverName,
            auto_post: autoPost,
          },
          auditRecordsPath(siteId)
        )
      )
      .then(TruckSystemService.processResponse);
  }
}
