import React, { ReactElement } from "react";
import { Table } from "antd";
import moment from "moment";

import useAuditRecordListState from "./useAuditRecordListState";
import DateSelectorModal from "components/DateSelectorModal/DateSelectorModal";
import useAuditList from "hooks/api/useAuditList";
import { isDateInValidRange } from "utils/dateUtils";

const DEFAULT_PAGE_SIZE = 12;
const validStartDate = moment().subtract(3, "weeks");
const validEndDate = moment().endOf("day");

const AuditRecordList = (): ReactElement => {
  const {
    columns,
    currentPage,
    setCurrentPage,
    dateSelectorVisible,
    setDateSelectorVisible,
    dateFilter,
    handleSubmitDates,
    roundFilter,
    loadNumberFilter,
    rationNameFilter,
    ingredientNameFilter,
    truckNameFilter,
    driverNameFilter,
    autoPostFilter,
    ...rest
  } = useAuditRecordListState();

  const { data, isPending } = useAuditList({
    page: currentPage,
    dateStart: dateFilter?.dateStart?.format("YYYY-MM-DD"),
    dateEnd: dateFilter?.dateEnd?.format("YYYY-MM-DD"),
    round: roundFilter,
    loadNumber: loadNumberFilter,
    rationName: rationNameFilter,
    ingredientName: ingredientNameFilter,
    truckName: truckNameFilter,
    driverName: driverNameFilter,
    autoPost: autoPostFilter,
    ...rest,
  });

  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.data}
        loading={isPending}
        className={"custom-table"}
        rowKey={(record) => record.id}
        pagination={{
          onChange: setCurrentPage,
          current: currentPage,
          total: data?.meta?.total,
          pageSize: DEFAULT_PAGE_SIZE,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        data-testid={"audit-record-list-table"}
      />
      {dateSelectorVisible && (
        <DateSelectorModal
          submit={handleSubmitDates}
          modalVisible={dateSelectorVisible}
          showModal={setDateSelectorVisible}
          dateStart={dateFilter?.dateStart}
          dateEnd={dateFilter?.dateEnd}
          disabledDates={(date) =>
            isDateInValidRange(date, validStartDate, validEndDate)
          }
        />
      )}
    </>
  );
};

export default AuditRecordList;
