import { rest } from "msw";
import { auditsFixture } from "__fixtures__/audits";
import { buildPaginatedResponse } from "testUtils/helpers";

const auditControllerHandlers = [
  rest.get(`*/admin-cms/site/:siteId/audit-records`, (_req, res, ctx) => {
    return res(ctx.json(buildPaginatedResponse<Audit>(auditsFixture)));
  }),
];

export default auditControllerHandlers;
