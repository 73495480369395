import React, { useState, useMemo, ReactElement, ElementType } from "react";
import { Divider, Tabs } from "antd";
import { ColumnType } from "antd/es/table";
const TabPane = Tabs.TabPane;

import { FeatureFlag, useFeatureFlags } from "contexts/FeatureFlagContext";
import ModuleDetail from "components/common/ModuleDetail";
import SiteUserList from "components/common/SiteUserList";
import SiteAdminHeader from "components/common/SiteAdminHeader";
import DeadAnimalList from "components/common/DeadAnimalList";
import PendingRegimenList from "components/common/PendingRegimenList";
import ColumnSelector from "components/common/ColumnSelector";
import SessionList from "components/SessionList/SessionList";
import { useSite } from "contexts/SiteDetailsContext";
import auditLogo from "img/icons/tab_audits.svg";
import deadLogo from "img/icons/tab_deads.svg";
import usersLogo from "img/icons/tab_users.svg";
import treatmentsLogo from "img/icons/tab_treatments.svg";
import routesLogo from "img/icons/tab_routes.svg";
import { FEATURE_FLAGS } from "utils/appFlags";
import AuditRecordList from "./components/AuditRecordList/AuditRecordList";

type SiteManagementTab = {
  key: string;
  tabIcon: string;
  tabLabel: string;
  tabComponent: ElementType;
  featureFlag: FeatureFlag;
  hasAdditionalDisplayColumns: boolean;
};

const siteManagementTabs: SiteManagementTab[] = [
  {
    key: "site_users",
    tabIcon: usersLogo,
    tabLabel: "User List",
    tabComponent: SiteUserList,
    featureFlag: undefined,
    hasAdditionalDisplayColumns: false,
  },
  {
    key: "treatments",
    tabIcon: treatmentsLogo,
    tabLabel: "Treatments",
    tabComponent: PendingRegimenList,
    featureFlag: FEATURE_FLAGS.TREATMENT_LIST,
    hasAdditionalDisplayColumns: true,
  },
  {
    key: "dead_animals",
    tabIcon: deadLogo,
    tabLabel: "Deads",
    tabComponent: DeadAnimalList,
    featureFlag: undefined,
    hasAdditionalDisplayColumns: false,
  },
  {
    key: "sessions",
    tabIcon: routesLogo,
    tabLabel: "Routes",
    tabComponent: SessionList,
    featureFlag: FEATURE_FLAGS.ROUTE_SESSION_LIST,
    hasAdditionalDisplayColumns: false,
  },
  {
    key: "audits",
    tabIcon: auditLogo,
    tabLabel: "TS Post Method",
    tabComponent: AuditRecordList,
    featureFlag: FEATURE_FLAGS.AUDIT_RECORDS_LIST,
    hasAdditionalDisplayColumns: false,
  },
];

const DEFAULT_TAB = "site_users";

const SiteManagerDashboard = ({
  siteModules,
}: {
  siteModules: (unknown & { id: string | number })[];
}): ReactElement => {
  const { site, siteAdminId } = useSite();
  const [tab, setTab] = useState(DEFAULT_TAB);
  const [additionalDisplayColumns, setAdditionalDisplayColumns] = useState([]);
  const [columnSelectorModal, setColumnSelectorModal] = useState([]);

  const addOrRemoveColumn = (column: ColumnType<unknown>, checked: boolean) => {
    if (checked) {
      setColumnSelectorModal((_columnSelectorModal) => [
        ..._columnSelectorModal,
        column,
      ]);
    } else {
      setColumnSelectorModal((_columnSelectorModal) =>
        _columnSelectorModal.filter(
          (oldColumn) => oldColumn.dataIndex !== column.dataIndex
        )
      );
    }
  };

  const { hasFlag } = useFeatureFlags();

  const tabs = useMemo(
    () =>
      siteManagementTabs.map(
        ({
          key,
          tabIcon,
          tabLabel,
          tabComponent: TabComponent,
          featureFlag,
          hasAdditionalDisplayColumns,
        }) =>
          ((featureFlag && hasFlag(featureFlag)) || !featureFlag) && (
            <TabPane
              tab={
                <span>
                  <img
                    src={tabIcon}
                    alt={"Turnkey Logo"}
                    className="tab-icon"
                  />
                  {tabLabel}
                </span>
              }
              key={key}
            >
              <TabComponent
                site={site}
                siteAdminId={siteAdminId}
                {...(hasAdditionalDisplayColumns
                  ? {
                      additionalDisplayColumns: [
                        ...additionalDisplayColumns,
                      ].sort((column1, column2) => column1.id - column2.id),
                    }
                  : {})}
              />
            </TabPane>
          )
      ),
    [hasFlag, site, siteAdminId, additionalDisplayColumns]
  );

  return (
    <div className={"detail-page"}>
      <SiteAdminHeader site={site} />

      <Divider />

      <div className={"row start-md module-list"}>
        {siteModules ? (
          siteModules.map((module) => (
            <ModuleDetail
              module={module}
              site={site}
              showAction={false}
              key={module.id}
            />
          ))
        ) : (
          <span>No Active Modules Found for this Site</span>
        )}
      </div>

      <div
        className={"list-page site-details tabbed-list"}
        style={{ marginTop: "12px" }}
      >
        <Tabs
          type="card"
          activeKey={tab}
          destroyInactiveTabPane={true}
          onTabClick={(key) => setTab(key)}
          tabBarExtraContent={
            tab === "treatments" && {
              left: (
                <ColumnSelector
                  additionalDisplayColumns={additionalDisplayColumns}
                  columnSelectorModal={columnSelectorModal}
                  handleOkColumnModal={() =>
                    setAdditionalDisplayColumns(columnSelectorModal)
                  }
                  handleCancelColumnModal={() =>
                    setColumnSelectorModal(additionalDisplayColumns)
                  }
                  addOrRemoveColumn={addOrRemoveColumn}
                />
              ),
            }
          }
        >
          {tabs}
        </Tabs>
      </div>
    </div>
  );
};

export default SiteManagerDashboard;
