import { Moment } from "moment";

export const hasBothDates = (startDate: string, endDate: string): boolean =>
  !!startDate && !!endDate;

export const isDateInValidRange = (
  date: Moment,
  startDate: Moment,
  endDate: Moment
): boolean => {
  return date && (date.isAfter(endDate) || date.isBefore(startDate));
};
