import { BASE_PATH_ADMIN_CMS } from "services/constants";
import { AxiosHeaders, AxiosPromise, AxiosResponse } from "axios";
import { ApiError } from "./ApiError";

export type ApiResponse<T> = AxiosPromise<BaseApiResponse<T>>;
export type PaginatedApiResponse<T> = AxiosPromise<
  BaseApiResponse<T> & { metadata: PaginationMetadata }
>;
export type LegacyPaginatedApiResponse<T> = AxiosPromise<
  BaseApiResponse<T> & PaginationMetadata
>;

export class BaseService {
  protected static buildRequestURL(
    searchParams: Record<string, string | number | undefined>,
    path: string,
    basePath?: string
  ): string {
    const params = new URLSearchParams();
    Object.entries(searchParams).forEach(
      ([key, value]) =>
        value !== undefined &&
        value !== "" &&
        value !== null &&
        params.set(key, `${value}`)
    );
    return `${basePath ?? BASE_PATH_ADMIN_CMS}${path}?${params.toString()}`;
  }

  protected static processResponse = <
    T extends string | { data: unknown; meta?: PaginationMetadata }
  >(
    response: AxiosResponse<T>
  ): AxiosResponse<T>["data"] => {
    if (response.status < 200 || response.status >= 400) {
      throw new ApiError(response.status, response.statusText, response.data);
    }

    return response.data;
  };

  protected static mockData<T>(data: BaseApiResponse<T>): ApiResponse<T> {
    return new Promise((resolve) => {
      resolve({
        data,
        status: 200,
        statusText: "OK",
        config: {
          headers: new AxiosHeaders({
            "Content-Type": "application/json",
          }),
        },
        headers: {},
      });
    });
  }

  protected static paginatedMockData<T>(
    data: BaseApiResponse<T> & { metadata: PaginationMetadata }
  ): PaginatedApiResponse<T> {
    return new Promise((resolve) => {
      resolve({
        data,
        status: 200,
        statusText: "OK",
        config: {
          headers: new AxiosHeaders({
            "Content-Type": "application/json",
          }),
        },
        headers: {},
      });
    });
  }

  public static getUseMock(): boolean {
    let useMock = false;
    try {
      useMock = process.env.RETURN_MOCK_API_RESPONSES === "true";
    } catch (error) {
      console.error("Unable to find RETURN_MOCK_API_RESPONSES env variable");
    }
    return useMock;
  }
}
