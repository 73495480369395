import { screen, waitForElementToBeRemoved } from "@testing-library/react";

const TEN_SECONDS = 10000;

export const assertLoadingStateNotInDocument = async (
  timeout?: number
): Promise<void> => {
  await waitForElementToBeRemoved(screen.queryByTestId("loading-state"), {
    timeout: timeout ?? TEN_SECONDS,
  });
};

export const assertTableDataLoaded = async (
  timeout?: number
): Promise<void> => {
  await waitForElementToBeRemoved(screen.queryByText("No data"), {
    timeout: timeout ?? TEN_SECONDS,
  });
};

export function buildPaginatedResponse<T>(data: Array<T>): {
  data: Array<T>;
  metadata: PaginationMetadata;
} {
  return {
    data,
    metadata: {
      total: data.length,
      per_page: 10,
      current_page: 1,
      last_page: 1,
      next_page_url: null,
      prev_page_url: null,
      from: 1,
      to: data.length,
    },
  };
}
