export const auditsFixture: Array<Audit> = [
  {
    id: "2f9c3348-0604-43ca-ad3a-94e205076191",
    site_id: 1,
    remote_id: 1,
    batch_date: "2024-01-01",
    target: "TEST_INGREDIENT_1",
    zone: "TEST_ZONE_1",
    audit_date: "2024-01-01",
    ration_name: "TEST_RATION_1",
    called_amount: 500,
    actual_amount: 500,
    feeding: 1,
    load_number: 1,
    transaction_type: "I",
    truck_name: "TEST_TRUCK_1",
    driver_name: "TEST_DRIVER_1",
    called_mix_time: 1,
    actual_mix_time: 1,
    called_rotation_count: 1,
    actual_rotation_count: 1,
    auto_post: true,
  },
];
