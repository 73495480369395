import React, { useState } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";
import OnOutsideClick from "react-outclick";

const SearchFilterInput = ({
  confirm,
  onClear,
  onSearch,
  value,
  inputType = "text",
}: any) => {
  const [localValue, setLocalValue] = useState(value);

  return (
    <OnOutsideClick
      onOutsideClick={() => {
        setLocalValue(value);
      }}
    >
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search`}
          value={localValue}
          onChange={(e) => {
            setLocalValue(e.target.value ? e.target.value : "");
          }}
          onPressEnter={() => {
            onSearch(localValue);
            confirm({ closeDropdown: true });
          }}
          type={inputType}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => {
            onSearch(localValue);
            confirm({ closeDropdown: true });
          }}
          size="small"
          icon={<SearchOutlined />}
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            onClear();
            confirm({ closeDropdown: true });
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    </OnOutsideClick>
  );
};

export default SearchFilterInput;
